import apple_DE from "./apple_DE.svg";
import apple_EN from "./apple_EN.svg";
import apple_IT from "./apple_IT.svg";
import play_DE from "./play_DE.png";
import play_EN from "./play_EN.svg";
import play_IT from "./play_IT.svg";
import easy_uc from "./easy-uc.png";

export default {
  apple_DE,
  apple_EN,
  apple_IT,
  play_DE,
  play_EN,
  play_IT,
  easy_uc,
};
