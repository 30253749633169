import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    detection: {
      order: [
        "querystring",
        "navigator",
        "htmlTag",
        "path",
        "localStorage",
        "sessionStorage",
        "subdomain",
        "cookie",
      ],
      lookupQuerystring: "lng",
      lookupCookie: "i18next",
      lookupLocalStorage: "i18nextLng",
      lookupSessionStorage: "i18nextLng",

      // cache user language
      caches: ["localStorage"],
      excludeCacheFor: ["cimode"],
      //cookieMinutes: 10,
      //cookieDomain: 'myDomain'
    },
    debug: true,
    fallbackLng: "it",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      it: {
        translation: {
          welcome: {
            message: "Benvenuto in Easy UC",
            desktop:
              "Per cominciare a usare Easy UC apri questo link sul tuo smartphone",
            click_here_ios:
              "Se Easy UC è già installato clicca qui sopra per accedere.",
          },
        },
      },
      en: {
        translation: {
          welcome: {
            message: "Welcome to Easy UC",
            desktop:
              "To begin using Easy UC, please open this link on your smartphone",
            click_here_ios:
              "If Easy UC is already installed click up here to login.",
          },
        },
      },
    },
  });

export default i18n;
